import React, { Component } from "react";
import Header from '../Image/header.png';
import MHeader from '../Image/mobileheader.png';
import { Link } from "react-router-dom";

import About from "./About";

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import "bootstrap-icons/font/bootstrap-icons.css";
import '../dist/App.css';
import '../dist/skeleton.css';

class HeaderAbout extends Component {

    render() {

        return (

            <div style={{ padding: "20px 20px 0 20px" }}>
                <nav className="navbar navbar-expand-lg navbar-dark  position-absolute" style={{ background: "#f2f2f22b", width: "90%" }}>
                    <div className="container-fluid">

                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse " id="navbarNav">
                            <ul className="navbar-nav  ms-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                    <Link className='nav-link active' to="/"><b>Home</b></Link >
                                </li>
                                <li className="nav-item">
                                    <Link className='nav-link active' to="/about"> <i className="bi bi-credit-card-fill"></i> Info Angusuran</Link >
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
                <img src={MHeader} alt="Bootstrap Icons" className="d-block d-sm-none d-md-none d-lg-none" width="100%" style={{ borderRadius: "20px" }} />
                <img src={Header} alt="Bootstrap Icons" className="d-none d-sm-none d-md-block d-lg-block" width="100%" style={{ borderRadius: "20px" }} />
                <About  text="Cek Data Angsuran"/>
            </div>
        )
    }
}

export default HeaderAbout