// with import

import header from '../Image/Group 23.svg';
import header1 from '../Image/header1.svg';
import header2 from '../Image/header2.svg';
import header3 from '../Image/header3.svg';
import header4 from '../Image/header4.svg';
import logo from '../Image/LogoBuanaTransparent.png';
import { Link } from 'react-router-dom';


const Header = () => {
    return (
        <div className="hero py-4 text-lg-start">
        <div className="container">
            <div className="row flex-lg-row-reverse">
                <div className="col-6 mx-auto col-lg-5 mx-lg-0">
                    <img src={header} className="img-fluid  mx-auto d-none d-sm-block" alt="Bootstrap Icons" width="100%" height="340" />
                           
                </div>
                <div className="col-lg-7">
    
                    <nav className="navbar navbar-light bg-white">
                        <div className="container">
                            <Link className="navbar-brand" to="#">
                                <img src={logo} alt="" width="100%" height="44" />
                            </Link>
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
                                aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarNavDropdown">
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                    <Link className='nav-link' to="/">Home</Link >
                                    </li>
                                    <li className="nav-item">
                                    <Link className='nav-link' to="/about"> <i className="bi bi-credit-card-fill"></i> Informasi Angsuran</Link >
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                    <h1 className="mt-4">
                    <b>
                        
                        Hallo Ada Yang <br/>
                        Bisa Dibantu?<br/>
                        Bersama <span style={{ color:"#16b608" }}>Buana</span><br/>
                        Mimpi Jadi Realita.
                    </b>
                </h1>
                  

                <a type="button" href='https://wa.me/6281110651000' target={'_blank'} rel="noopener noreferrer" className="btn btn-sm btn-success mt-2 bg-hijau">
                    <i className="bi bi-whatsapp"></i> Whatsapp
                </a>
                <div className="row mt-4">
                    <div className="col-4 col-sm-3 col-md-2 p-3">
                    <img src={header1}  alt="header 1" width={100}/>
                    </div>
                    <div className="col-4 col-sm-3 col-md-2 p-3">
                    <img src={header2}  alt="header 2" width={100}/>
                    </div>
                    <div className="col-4  col-sm-3 col-md-2 p-3">
                    <img src={header3}  alt="header 3" width={100}/>
                    </div>
                    <div className="d-none d-sm-none d-md-block d-lg-block col-sm-3 col-md-2 p-3">
                    <img src={header4}  alt="header 4" width={100}/>
                    </div>
                </div>
                <div className="row" style={{ color:"#16b608" }}>
                    <div className="col-4 col-md-3 p-3">
                        <h5><b><i className="bi bi-whatsapp"></i> Fast Response</b> </h5>
                    </div>
                    <div className="col-4 col-md-3 p-3">
                        <h5><b><i className="bi bi-emoji-smile-fill"></i> Have Fun</b> </h5>
                    </div>
                    <div className=" col-4 col-md-3 p-3">
                        <h5><b><i className="bi bi-shield-fill-check"></i> Secure</b> </h5>
                    </div>
                </div>



    
                </div>
            </div>
        </div>
    </div>
                )
}

 export default Header