

const API_URL = 'https://pangrango.buanafinance.co.id/ApiPengaduan/';
// Please use your own API key
const API_KEY = 'BNF22';



export {
  API_URL,
  API_KEY
}