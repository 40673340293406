
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import "bootstrap-icons/font/bootstrap-icons.css";
import '../dist/App.css';
import '../dist/purpose.css';
import Header from "./header";
import Content from "./Content";
const Home = () => {
    return (
        <div>
        <Header />
        <Content />
        </div>
    )
}
 
export default Home