
import Home from "./components/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HeaderAbout from "./components/HeaderAbout";
import Footer from "./components/Footer";

function App() {
 
 
  return (
    <div>
   
  <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home/>}/>
          <Route path="/Home" element={<Home/>}/>
          <Route path="/about" element={<HeaderAbout/>}/>
        </Routes>
        </BrowserRouter>
        <Footer />
    </div>
  );
}
 
export default App;