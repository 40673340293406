import React, { Component } from "react";
import credit from '../Image/credit.svg';
import axios from "axios";
import sha256 from "js-sha256";
import { API_URL, API_KEY } from '../config';
import LoadingSkeleton from '../element/LoadingSkeleton';
import DataAngsuran from '../element/DataAngsuran';



class About extends Component {

    state = {
        idnumber: '',
        type: '',
        name: '',
        angsuran: null,
        loading: false
    }
    handleChange = e => {
        this.setState({ input: e.target.value });
    };

    handleIDNumber = e => {
        this.setState({ inputidnumber: e.target.value });
    };
    hideElement = e => {

        this.setState({
            angsuran: false
        });
    }
    handleSubmit = event => {
        event.preventDefault();
        this.setState({
            loading: true
        });
        const user = {
            name: this.state.input,
            idnumber: this.state.inputidnumber
        };

        this.setState({
            angsuran: false
        });

        const type = event.target.value;
        console.log(type);
        /* ..Get Token. */
        const signature = sha256(user.idnumber + user.name + API_KEY);
        const params = new URLSearchParams()
        params.append('username', user.name)
        params.append('grant_type', 'password')
        params.append('signature', signature)
        params.append('noktp', user.idnumber)
        params.append('type', type)

        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }

        axios.post(`${API_URL}api/oauth/token`, params, config)
            .then((result) => {



                axios({
                    method: 'post',
                    url: `${API_URL}customer/checkangsuran`,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + result.data.access_token
                    },
                    data: {
                        agrement: user.name,
                        idnumber: user.idnumber,
                        type: type,// This is the body part
                    }
                })
                    .then((result) => {


                        if (result.data.statuss === "Not Found") {
                            this.setState({
                                loading: false,
                                jumlah: result.data.length,
                                cust_name: "Harap Masukan ID Number dan No Aggrement Dengan Valid"
                            })
                        } else {

                            this.setState({

                                loading: false,
                                jumlah: result.data.length,
                                angsuran: result.data[0]
                            });
                        }


                    })
                    .catch((err) => {
                        console.log(err);
                        this.setState({
                            jumlah: 0,
                            loading: false,
                            angsuran: true
                        });
                    });


            })
            .catch((err) => {
                console.log(err);
                this.setState({
                    jumlah: 0,
                    loading: false,
                    angsuran: true
                });
            })
    }
    render() {

        const { angsuran, loading, jumlah, cust_name } = this.state;
        return (

            <div className="container mt-5 " >
                <div className="row">

                    <div className="col-md-7 ">

                        <h4> {this.props.text} </h4>
                        <hr />

                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="home-tab" onClick={this.hideElement} data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Personal</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="profile-tab" onClick={this.hideElement} data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">Company</button>
                            </li>

                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex="0">
                                <form onSubmit={this.handleSubmit}>
                                    <div className="row mt-3 mt-md-3">
                                        <div className="col-md-5">
                                            <div className="input-group-sm mb-3">
                                                <label >No Aggrement</label>
                                                <input type="number" name="name" placeholder="Ex: 213128823099" onChange={this.handleChange} className="form-control form-control-sm" />
                                            </div>
                                        </div>

                                        <div className="col-md-5">
                                            <div className="input-group-sm mb-3">
                                                <label >No KTP</label>
                                                <input type="number" name="name" placeholder="Ex: 32131232928328" onChange={this.handleIDNumber} className="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="gap-2">
                                                <label className="text-white">_</label>
                                                <button type="submit" onClick={this.handleSubmit} value="P" className="form-control btn btn-sm btn-success">Cek Data</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabIndex="0">
                                <form onSubmit={this.handleSubmit}>
                                    <div className="row mt-3 mt-md-3">
                                        <div className="col-md-5">
                                            <div className="input-group-sm mb-3">
                                                <label >No Aggrement</label>
                                                <input type="number" name="name" placeholder="Ex: 213128823099" onChange={this.handleChange} className="form-control form-control-sm" />
                                            </div>
                                        </div>

                                        <div className="col-md-5">
                                            <div className="input-group-sm mb-3">
                                                <label >NPWP</label>
                                                <input type="number" name="npwp" placeholder="Ex: 32131232928328" onChange={this.handleIDNumber} className="form-control form-control-sm" />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="gap-2">
                                                <label className="text-white">_</label>
                                                <button type="submit" onClick={this.handleSubmit} value="C" className="form-control btn btn-sm btn-success">Cek Data</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>


                        {loading ? <LoadingSkeleton /> : null}

                        {angsuran ? <DataAngsuran
                            jumlah={jumlah}
                            agrement={angsuran.agrement}
                            angsuranke={angsuran.angsuranke}
                            cust_name={angsuran.cust_name ? angsuran.cust_name : cust_name}
                            jatuhTempo={angsuran.jatuhTempo}
                            jmlAngsuran={angsuran.jmlAngsuran}
                        />
                            : null}



                    </div>
                    <div className="col-md-1"></div>
                    <div className="col-12 col-md-4">
                        {/* <div className="shadow-sm p-3 mb-5 bg-body rounded"> */}
                        <img src={credit} alt="Bootstrap Icons" width="100%" className="mt-5" style={{ borderRadius: "20px", filter: "drop-shadow(5px 5px 5px rgba(0,0,0,0.3))" }} />

                    </div>
                </div>
            </div>
        )
    }
}

export default About