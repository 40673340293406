import bg from '../Image/9428b556.png';
import master from '../Image/image (2).png';
import lottie from "lottie-web";
import reactLogo from "../Image/customercare.json";
import React, { useEffect } from "react";



const Content = () => {
    useEffect(() => {
        const instance = lottie.loadAnimation({
          container: document.getElementById('lottie'),
          renderer: 'svg',
          loop: true,
          autoplay: true,
          animationData: reactLogo
        });
    
        // Return clean up function here
        return () => instance.destroy();
      }, []);
    return (
        <div>
      


      <section className="slice slice-xl has-floating-items " style={{background: "linear-gradient(50deg, #129034 0, #7fc41b 100%) ", marginBottom: "10rem"}} id="sct-call-to-action">
      <a href="#sct-call-to-action" className="tongue tongue-up tongue-section-secondary" data-scroll-to>
      <i className="bi bi-arrow-up-circle-fill"></i>
    </a>
    <div className="container text-center">
      <div className="row">
        <div className="col-12">
          <h1 className="text-white">Contact Our Bu Ana 24/7</h1>
          <div className="row justify-content-center mt-4">
            <div className="col-lg-8">
              <p className="lead text-white">
             Bu Ana berkomitmen untuk selalu memberikan pelayanan terbaik bagi setiap Nasabah, termasuk dalam pelayanan
             penanganan pengaduan dan keluhan kami siap membantu untuk penyelesaian dan pelayanan terbaik
             bagi Nasabah kami tercinta
              </p>
             
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div className="container floating-items">
      <div className="icon-floating icon-lg bg-white floating text-success">
        <span></span>
        <i className="svg-inject bi bi-chat-left-dots"></i>
      </div>
      <div className="icon-floating icon-lg bg-white floating text-success">
        <span></span>
    
        <i  className="svg-inject bi bi-apple"></i>
       </div>
      <div className="icon-floating icon-sm bg-white floating text-success">
        <span></span>
        <i className="bi bi-journal-bookmark-fill"></i>
      </div>
      <div className="icon-floating icon-lg bg-white floating text-success">
        <span></span>
        <i className="bi bi-robot"></i>
      </div>
      <div className="icon-floating bg-white floating text-success">
        <span></span>
        <i className="bi bi-whatsapp"></i>
      </div>
      <div className="icon-floating icon-sm bg-white floating text-success">
        <span></span>
      
        <i className="bi bi-android2"></i>
      </div>
    </div>
  </section>


  <main className="container mt-5" id="content">
        <section style={{minHeight: "230px"}}>
            <div className="row">
                <div className="col-md-4">
                    <img src={bg} alt="0"/>
                </div>
                
                <div className="col-md-8 img-post d-block d-lg-none d-md-none">
                    <img src={master} alt="" className=" mt-5 image-radius" />
                </div>
                <div className="col-md-8  d-none d-sm-none d-lg-block d-md-block">
                    <img src={master} alt="" className=" mt-5 image-radius" />
                </div>
            </div>
        </section>

      <section>
        <center className="mt-5">
            <h2><b style={{color: "#16b608"}}><u>Contact US</u> </b></h2>
            <br/>
        </center>
        <div className="row mt-5" >
            <div className="col-md-6">
            <div className="mapouter">
              <div className="gmap_canvas">
                <iframe style={{width:"100%", height:"339px"}} id="gmap_canvas" src="https://maps.google.com/maps?q=tokopedia%20tower&t=&z=13&ie=UTF8&iwloc=&output=embed" />
                <br/>
                <a href="https://www.embedgooglemap.net"></a>
                </div>
                </div>
                <br/>
                <h5 className='text-success'>HEAD OFFICE</h5>
                PT Buana Finance Tbk.<br/>
Tokopedia Tower - Ciputra World 2<br/>
Lt. 38 Unit A-F<br/>
Jl. Prof.Dr. Satrio Kav 11<br/>
Jakarta Selatan<br/>
Phone : (021) 5080 6969<br/>
Fax : (021) 5080 6996<br/>
            </div>
            <div className="col-md-6" >   
             <div className="hero__animation" id="lottie"></div>
            </div>
        </div>
      </section>
    </main>

  </div>
    )
}
 
export default Content