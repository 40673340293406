import React from 'react';

const DataAngsuran = ({ agrement, angsuranke, cust_name, jatuhTempo, jmlAngsuran, jumlah }) => {
  return(
<div id="viewAngsuran" >
<h5 className=" mt-5">Informasi Angsuran : {cust_name}</h5>
<div className="table-responsive" >
    <hr />
    <table className="table table-sm table-striped table-bordered">
        <thead>
            <tr className="bg-success text-white">
                <th>No Aggrement</th>
                <th>Angsuran Ke</th>
                <th>Customer Name</th>
                {/* <th>Denda</th> */}
                <th>Jatuh Tempo</th>
                <th>Jml Angsuran</th>
            </tr>
        </thead>
        <tbody>
            {jumlah !== 0 ? (
                <tr>
                    <td>{agrement} </td>
                    <td>{angsuranke}</td>
                    <td>{cust_name}</td>
                    {/* <td>{this.state.denda}</td> */}
                    <td>{jatuhTempo}</td>
                    <td>{jmlAngsuran}</td>
                </tr>
            ) : (
                <tr>
                    <td colSpan={5} align="center">Data Tidak Di Temukan </td>
                </tr>
            )}
        </tbody>
    </table>
</div>

</div>
  )
}

export default DataAngsuran;