
import Header from '../Image/footer.svg';
import logo from '../Image/LogoBuanaTransparent.png';

const Footer = () => {
    return (
        <div>
            <div className='p-5 text-white' style={{ backgroundImage: `url(${Header})`, backgroundSize: "cover" }}>
                <div className='container-fluid'>
                    <div className='row w-100 pt-3 ms-5'>
                        <div className='col-lg-1'>

                        </div>
                        <div className='col-12 col-sm-12 col-md-5 col-lg-5 pt-3'>
                            <div className='bg-white p-1 rounded-5 w-50'>
                                <img src={logo} alt="" width="100%" />
                            </div>

                        </div>
                        <div className='col-12 col-sm-12 col-md-2 col-lg-2 pt-3'>
                            <b className='ps-3'>About Us</b>
                            <ul >
                                <li>
                                    <a className='nav-link active' href='https://www.buanafinance.co.id/aboutus/buanafinanceinbrief'  target={'_blank'} rel="noopener noreferrer">
                                        Buana Finance in Brief
                                    </a>
                                </li>
                                <li>
                                    <a className='nav-link active' href='https://www.buanafinance.co.id/aboutus/theshareholders'  target={'_blank'} rel="noopener noreferrer">
                                        The Shareholders
                                    </a>
                                </li>
                                <li>
                                    <a className='nav-link active' href='https://www.buanafinance.co.id/aboutus/boardofcomissioners'  target={'_blank'} rel="noopener noreferrer">
                                        Board Of Commisioner
                                    </a>
                                </li>
                                <li>
                                    <a className='nav-link active' href='https://www.buanafinance.co.id/aboutus/directors'  target={'_blank'} rel="noopener noreferrer">
                                        Directors
                                    </a>
                                </li>
                                <li>
                                    <a className='nav-link active' href='https://www.buanafinance.co.id/aboutus/auditcommittee'  target={'_blank'} rel="noopener noreferrer">
                                        Audit Commite
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className='col-12 col-sm-12 col-md-2 col-lg-2  pt-3'>
                            <b className='ps-3'> Product and Service</b>
                            <ul >
                                <li >
                                    <a className='nav-link active' href='https://www.buanafinance.co.id/productandservices/whybuanafinance'  target={'_blank'} rel="noopener noreferrer">
                                        Why Buana Finance
                                    </a>
                                </li>
                                <li >
                                    <a className='nav-link active' href='https://www.buanafinance.co.id/productandservices/multipurposefinancing'  target={'_blank'} rel="noopener noreferrer">
                                        Multipurpose Financing
                                    </a>
                                </li>
                                <li >
                                    <a className='nav-link active' href='https://www.buanafinance.co.id/productandservices/investmentfinancing'  target={'_blank'} rel="noopener noreferrer">
                                        Investment Financing
                                    </a>
                                </li>
                                <li >
                                    <a className='nav-link active' href='https://www.buanafinance.co.id/productandservices/paymentmethods'  target={'_blank'} rel="noopener noreferrer">
                                        Payment Methods
                                    </a>
                                </li>
                                <li >
                                    <a className='nav-link active' href='https://www.buanafinance.co.id/productandservices/loansimulation'  target={'_blank'} rel="noopener noreferrer">
                                        Loan Simulation
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <footer className="navbar navbar-expand-lg bg-light">
                <div className="container-fluid">
                    <small className="small text-muted"  >Copyright 2022 PT Buana Finance tbk. All Right Reserved.</small>

                    <div className="collapse navbar-collapse">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <a className="nav-link active" aria-current="page" href='https://wa.me/6281110651000' target={'_blank'} rel="noopener noreferrer">
                                    <i className="bi bi-whatsapp"></i>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link active" href='https://www.facebook.com/BuanaFinanceid/' target={'_blank'} rel="noopener noreferrer">
                                    <i className="bi bi-facebook"></i>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link active" href='https://www.youtube.com/channel/UCY5gOVFHNpNob374NfJrsNw' target={'_blank'} rel="noopener noreferrer">
                                    <i className="bi bi-youtube"></i>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link active"  href='https://www.instagram.com/buanafinanceid/' target={'_blank'} rel="noopener noreferrer">
                                    <i className="bi bi-instagram"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>

        </div>
    )
}

export default Footer